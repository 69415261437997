import React from "react";

function Footer() {
  return (
    <div className="border-t border-primary-100 py-6">
      <div className="text-center text-lg text-para dark:text-white">
        <span> Ariful Islam | </span>
        <span>All Rights Reserved.</span>
      </div>
    </div>
  );
}

export default Footer;
