import React from "react";

function Qualifaction({ title, name, year, desc }) {
  return (
    <div className="mb-5 rounded-xl bg-primary-50 p-5 dark:bg-darkBg">
      <span className="font-semibold">{title}</span>
      <div className="my-2 h-[2px] w-full bg-tertiary"></div>
      <div className="flex justify-between text-primary-100">
        <span>{name}</span>
        <span>{year}</span>
      </div>
      <p className="mt-4">{desc}</p>
    </div>
  );
}

export default Qualifaction;
