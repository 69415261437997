import React from "react";

function SectionHeading({
  title,
  highlightText,
  plusSize = false,
  component = null,
}) {
  return (
    <div
      className={`${
        plusSize === true ? "text-4xl" : "text-3xl "
      } font-semibold leading-[130%] dark:text-white sm:text-3xl `}
    >
      {title}
      {component !== null ? (
        <span className="text-center text-primary-100">A{component}</span>
      ) : (
        <span className="text-primary-100">{highlightText}</span>
      )}
    </div>
  );
}

export default SectionHeading;
