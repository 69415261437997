import React from "react";
import SectionTitle from "./SectionTitle";
import SectionHeading from "./SectionHeading";
import Skill from "./Skill";
import hmtlImg from "../images/html.png";
import jsImg from "../images/js.png";
import nodeImg from "../images/node.png";
import reactImg from "../images/react.png";
import laravelImg from "../images/laravel.jpeg";
import nextImg from "../images/next.jpeg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
function Skills() {
  gsap.registerPlugin(ScrollTrigger);
  useGSAP(() => {
    gsap.from(".skill", {
      duration: 1,
      width: 0,
      opacity: 1,
      stagger: 0.3,
      ease: "bounce",
      scrollTrigger: {
        // markers: true,
        trigger: ".skill",
        start: "top bottom",
        toggleActions: "restart resume restart pause",
      },
    });
  });

  return (
    <div id="skillsSection" className="mb-12 mt-12">
      <SectionTitle title="My Skills" />
      <div className="py-4">
        <SectionHeading title="My Skills " highlightText="Overview" />
      </div>
      <p className="text-xl">
        Offering a glimpse into my expertise across the full-stack spectrum and
        showcasing my technical prowess.
      </p>

      {/* Skills */}
      <Skill title="Html" parcentage="96%" logoImg={hmtlImg} />
      <Skill title="React" parcentage="95%" logoImg={reactImg} />
      <Skill title="Javascript" parcentage="90%" logoImg={jsImg} />
      <Skill title="next.js" parcentage="92%" logoImg={nextImg} />
      <Skill title="Node.js" parcentage="80%" logoImg={nodeImg} />
      <Skill title="Laravel" parcentage="80%" logoImg={laravelImg} />
    </div>
  );
}

export default Skills;
