import React, { useState } from "react";

function Navigation() {
  const [activeMenu, setActiveMenu] = useState("aboutSection");
  let menus = [
    {
      label: "About",
      type: "link",
      targetId: "aboutSection",
    },
    {
      label: "Skills",
      type: "link",
      targetId: "skillsSection",
    },
    {
      label: "Projects",
      type: "link",
      targetId: "projectsSection",
    },
    {
      label: "Experience",
      type: "link",
      targetId: "experienceSection",
    },
    {
      label: "Endorsement",
      type: "link",
      targetId: "endorsementSection",
    },
    {
      label: "Contact Me",
      type: "button",
      targetId: "contactSection",
    },
  ];

  const scrollToSection = (targetId) => {
    setActiveMenu(targetId);
    const targetElement = document.getElementById(targetId);
    let yOffset = -100;
    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <div>
      <ul className="navMenu flex items-center gap-12  lg:gap-8 tab:gap-4">
        {menus.map((menu, indx) => (
          <li
            className={`${
              menu.targetId === activeMenu && menu.type !== "button"
                ? " font-semibold !text-primary-100"
                : ""
            } ${menu.type === "button" && "button"} dark:text-white`}
            key={indx}
            onClick={() => scrollToSection(menu.targetId)}
          >
            {menu.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Navigation;
