import React from "react";
import SectionTitle from "./SectionTitle";
import SectionHeading from "./SectionHeading";
import Experience from "./Experience";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Experiences() {
  gsap.registerPlugin(ScrollTrigger);
  useGSAP(() => {
    gsap.from(".exp", {
      duration: 0.5,
      y: 100,
      opacity: 1,
      stagger: 0.1,
      ease: "circ",
      scrollTrigger: {
        // markers: true,
        trigger: ".exp",
        start: "top bottom",
        toggleActions: "restart pause resume pause",
      },
    });
  });

  let data = [
    {
      position: "Full Stack Developer",
      company: "Upspell",
      start: "January 2024",
      end: "Present",
      desc: "Here i did various digital marketing websites project that was giving this company a biggest revenue of my great works.",
    },
    {
      position: "Full Stack Developer",
      company: "SHUVO IT",
      start: "June 2022",
      end: "December 2023",
      desc: "Here i did various digital marketing websites project that was giving this company a biggest revenue of my great works.",
    },
    {
      position: "Front-End Developer",
      company: "Beauty Booth",
      start: "May 2020",
      end: "May 2022",
      desc: "Here i did 2 E-commerce Website that was giving this company a biggest revenue of my great works.",
    },
    {
      position: "Front-End Developer",
      company: "JAPAN MNC,",
      start: "October 2019",
      end: "April 2020",
      desc: "Here i did price comparasing website using vue.js, nuxt.js, laravel, python, node.js.",
    },
    {
      position: "Software Engineer",
      company: "ASP Innovation",
      start: "July 2018",
      end: "February 2019",
      desc: "Here i did travel agency project that was giving this company a biggest revenue of my great works.",
    },
  ];

  return (
    <div id="experienceSection" className="mb-12 mt-12">
      <SectionTitle title="Professional Engagements" />
      <div className="py-4">
        <SectionHeading title="Career " highlightText="Trajectory" />
      </div>
      <div className="mt-4">
        {data.length > 0 &&
          data.map(({ position, company, start, end, desc }, indx) => (
            <Experience
              key={indx}
              position={position}
              company={company}
              start={start}
              end={end}
              desc={desc}
              isLast={indx + 1 === data.length}
            />
          ))}
      </div>
    </div>
  );
}

export default Experiences;
