import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import AboutMe from "./components/AboutMe";
import Contacts from "./components/Contacts";
import Experiences from "./components/Experiences";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ProfileCard from "./components/ProfileCard";
import Projects from "./components/Projects";
import Qualifications from "./components/Qualifications";
import Recommendation from "./components/Recommendation";
import Skills from "./components/Skills";

function App() {
  const circleRef = useRef(null);
  const textRef = useRef(null);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const circle = circleRef.current;
    const text = textRef.current;
    const circleLength = circle.getTotalLength();

    gsap.set(circle, {
      strokeDasharray: circleLength,
      strokeDashoffset: circleLength,
      stroke: "red", // Initial color
    });

    const updateProgress = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const documentHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const percentage = Math.min((scrollTop / documentHeight) * 100, 100);

      const dashOffset = circleLength - (circleLength * percentage) / 100;

      gsap.to(circle, {
        strokeDashoffset: dashOffset,
        // stroke: `hsl(${percentage}, 100%, 50%)`,
        stroke: "#25B81D",
        duration: 0.5,
      });

      // Update the text content to display the percentage
      text.textContent = Math.round(percentage) + "%";
      setScrollPercentage(percentage);
    };

    window.addEventListener("scroll", updateProgress);
    return () => window.removeEventListener("scroll", updateProgress);
  }, []);

  return (
    <div className="">
      <Navbar />
      <div className="container mx-auto max-w-[1440px] px-28 xl:px-8 lg:px-8 md:pt-2 sm:px-4 ">
        <div>
          <div className="flex gap-[5%] pt-12 tab:flex-col md:pt-4 ">
            <div className="sticky top-[100px] h-[100vh] w-[33%] tab:static tab:mb-4 tab:h-auto tab:w-full  ">
              <ProfileCard />
            </div>
            <div className="w-[62%] tab:w-full ">
              <AboutMe />
              <Skills />
              <Projects />
              <Experiences />
              <Qualifications />
              <Recommendation />
              <Contacts />
              <Footer />
              <div
                className={`scroll-progress-container ${scrollPercentage < 10 ? "opacity-[-1]" : ""}`}
              >
                <svg viewBox="0 0 100 100" className="scroll-progress-svg">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    stroke="gray"
                    strokeWidth="8"
                  />
                  <circle
                    ref={circleRef}
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    strokeWidth="8"
                  />
                  <text
                    ref={textRef}
                    x="50"
                    y="50"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="24"
                    fill="#475467"
                  >
                    {Math.round(scrollPercentage)}%
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <About />
      <Skills /> */}
    </div>
  );
}

export default App;
