import React from "react";

function ContactFrom() {
  return (
    <form>
      <input
        required
        className="my-5 block w-full rounded-md border border-tertiary px-6 py-3 text-xl focus:border-primary-100 focus:outline-none"
        type="text"
        placeholder="Your Name"
      />
      <input
        required
        className="my-5 block w-full rounded-md border border-tertiary px-6 py-3 text-xl focus:border-primary-100 focus:outline-none"
        type="email"
        placeholder="Your Email"
      />
      <input
        required
        className="my-5 block w-full rounded-md border border-tertiary px-6 py-3 text-xl focus:border-primary-100 focus:outline-none"
        type="text"
        placeholder="Area of Interest"
      />
      <textarea
        placeholder="Message"
        className="my-5 block w-full rounded-md border border-tertiary px-6 py-3 text-xl focus:border-primary-100 focus:outline-none "
        rows={5}
      ></textarea>
      <button className=" duration-400 mt-6 block w-full rounded-md bg-primary-100 py-3 text-2xl font-bold text-white transition ease-in-out  hover:bg-primary-50 hover:text-primary-100">
        Submit
      </button>
    </form>
  );
}

export default ContactFrom;
