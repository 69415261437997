import React from "react";
import Qualifaction from "./Qualifaction";
import SectionTitle from "./SectionTitle";
import SectionHeading from "./SectionHeading";
let backgrounds = [
  {
    title: "BSC in CSE",
    name: "American Internationl University (AIUB)",
    year: "2014-2018",
    desc: "Obtained Bachelor of Science in Computer Science and Engineering from American International University (AIUB) in 2018.",
  },
  {
    title: "HSC",
    name: "Dhaka Oriental Collage",
    year: "2010-2012",
    desc: "Obtained Bachelor of Science in Computer Science and Engineering from American International University (AIUB) in 2018.",
  },
  {
    title: "SSC",
    name: "Abirpara Hight School",
    year: "2010",
    desc: "Obtained Bachelor of Science in Computer Science and Engineering from American International University (AIUB) in 2018.",
  },
];
function Qualifications() {
  return (
    <div className="mb-12 mt-12">
      <SectionTitle title="Qualifactions " />
      <div className="py-4">
        <SectionHeading title="Academic " highlightText="Journeys" />
      </div>
      <div className="mt-4">
        {/*  */}
        <div className="mt-8 tab:mt-2">
          {backgrounds &&
            backgrounds.map((background, indx) => (
              <Qualifaction
                key={indx}
                title={background.title}
                name={background.name}
                year={background.year}
                desc={background.desc}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Qualifications;
