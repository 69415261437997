import React from "react";

function SectionTitle({ title }) {
  return (
    <div className=" flex items-center gap-4 pt-8 text-2xl text-tertiary dark:text-darkTertiary tab:pt-4">
      <span>{title}</span>
      <span className=" inline-block h-[1px] w-16 bg-tertiary dark:bg-darkTertiary"></span>
    </div>
  );
}

export default SectionTitle;
