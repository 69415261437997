import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";
import { DarkModeSwitch } from "react-toggle-dark-mode";
function Navbar() {
  const [isSticky, setSticky] = useState(false);
  const [isDarkMode, setDarkMode] = React.useState(
    JSON.parse(localStorage.getItem("isDarkMode")) || false,
  );

  useEffect(() => {
    console.log(isDarkMode);
    if (isDarkMode == true) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  const toggleDarkMode = (checked) => {
    localStorage.setItem("isDarkMode", checked);
    setDarkMode(checked);
  };
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 10) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    // Add event listener to listen for scroll events
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={` ${isSticky && "stickyNavbar"} custom-shadow`}>
      <div className="container mx-auto max-w-[1440px] bg-white px-28 dark:bg-dark xl:px-8 lg:px-8 sm:px-4 ">
        <div className="flex items-center justify-between py-4">
          {/* left */}
          <div className="flex items-center gap-6 md:flex-1 md:justify-between">
            <a
              href="/"
              className="text-2xl font-bold text-primary-100 md:order-1"
            >
              <img src="/Logo.png" className="w-32" alt="" />
            </a>
            {/* mode button */}
            {/* <div className="">
              <div class="flex items-center justify-center w-full ">
                <label for="toggleB" class="flex items-center cursor-pointer">
                  <div class="relative">
                    <input type="checkbox" id="toggleB" class="sr-only" />

                    <div class="block bg-black  w-14  h-6 rounded-full"></div>

                    <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                  </div>
                </label>
              </div>
            </div> */}
            <div>
              <DarkModeSwitch
                // style={{ marginBottom: "2rem" }}
                checked={isDarkMode}
                onChange={toggleDarkMode}
                size={24}
                // style={{ height: 15, w: 15 }}
              />
            </div>
            <div className="hidden md:order-2 md:block">
              <svg
                className="h-8 w-8 fill-primary-100"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z"></path>
              </svg>
            </div>
          </div>
          {/* right  */}
          <div>
            <div className=" md:hidden">
              <Navigation />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
