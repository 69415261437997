import React from "react";
import SectionHeading from "./SectionHeading";
import { TypeAnimation } from "react-type-animation";
function AboutMe() {
  return (
    <div id="aboutSection" className=" mt-4">
      {/* <SectionTitle title="About Me" /> */}
      {/* name */}
      <div className=" pb-4 pt-0">
        <SectionHeading
          title="A glimpse into the mind of a visionary web creator "
          highlightText="Ariful"
          plusSize={true}
          component={
            <TypeAnimation
              cursor={false}
              sequence={["riful Islam", 2500, "riful Islam", 2000]}
              // style={{ fontSize: "2em" }}
              repeat={Infinity}
            />
          }
        />
      </div>

      {/* desc */}
      <p className="text- text-justify text-para dark:text-white">
        I am a seasoned full-stack developer adept at crafting seamless and
        user-centric web applications. With expertise in front-end technologies
        like React.js and back-end frameworks such as Node.js, I bring a
        holistic approach to development. My proficiency extends to database
        management, Git for version control, and deployment on platforms like
        Heroku and AWS. With a strong foundation in software engineering
        principles, a commitment to continuous learning, and a proven track
        record of delivering successful projects, I am eager to take on new
        challenges and contribute to innovative solutions in the ever-evolving
        tech landscape. Connect with me on [LinkedIn/GitHub] to explore
        potential collaborations.
      </p>
      {/* experience and projects */}
      <div className="mt-3 flex gap-6 md:gap-3 sm:mt-4 sm:flex-col">
        <div className="custom-shadow flex flex-1 items-center rounded-md bg-primary-50 px-12 py-3 dark:bg-darkBg md:px-3 sm:justify-center">
          <span className="mr-4 text-5xl font-bold text-secondary dark:text-white md:mr-2 md:text-3xl">
            4+
          </span>
          <span className="text-xl text-primary-100 md:text-lg">
            Years of Experience
          </span>
        </div>
        <div className="custom-shadow flex  flex-1 items-center rounded-md bg-primary-50 px-12 py-3 dark:bg-darkBg md:px-4 sm:justify-center">
          <span className="mr-4 text-5xl  font-bold text-secondary dark:text-white md:mr-2 md:text-3xl">
            32
          </span>
          <span className="text-xl text-primary-100 md:text-lg">
            Projects Completed
          </span>
        </div>
        {/* <div className="flex-1">
          <span>4+</span>
          <span>Years of Experience</span>
        </div> */}
      </div>
    </div>
  );
}

export default AboutMe;
