import React from "react";
import SectionTitle from "./SectionTitle";
import SectionHeading from "./SectionHeading";
import ProjectCard from "./ProjectCard";
import upspellImg from "../images/upspell.jpeg";
import seomissilesImg from "../images/seomissiles.jpeg";
import BBqa from "../images/BBqa.jpeg";
import BBbd from "../images/BBbd.jpeg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Projects() {
  gsap.registerPlugin(ScrollTrigger);
  useGSAP(() => {
    gsap.from(".project", {
      duration: 1,
      x: "250%",
      y: 100,
      opacity: 1,
      stagger: 0.1,
      ease: "circ",
      scrollTrigger: {
        // markers: true,
        trigger: ".project",
        start: "top bottom",
        toggleActions: "restart pause resume pause",
        onEnter: () => {
          document.documentElement.style.overflowX = "hidden";
        },
        onLeaveBack: () => {
          document.documentElement.style.overflowX = "auto";
        },
      },
    });
  });
  let projects = [
    {
      name: "Upspell",
      logo: upspellImg,
      url: "https://upspell.com/",
      desc: "This site leverages React, Next.js, Laravel, and Tailwind CSS, embodying a digital marketing agency.",
    },
    {
      name: "SEO Missiles",
      logo: seomissilesImg,
      url: "https://seomissiles.com/",
      desc: "It's a digital marketing agency website crafted with React, Next.js, Laravel, and Tailwind CSS.",
    },
    {
      name: "Beauty Booth Qatar",
      logo: BBqa,
      url: "https://beautyboothqa.com/",
      desc: "This is an e-commerce site developed with React, Next.js, Laravel, Node.js, and SASS",
    },
    {
      name: "Beauty Booth Bangladesh",
      logo: BBbd,
      url: "https://beautybooth.com.bd/",
      desc: "It's an e-commerce platform constructed with React, Next.js, Laravel, Node.js, and SASS.",
    },
  ];

  return (
    <div id="projectsSection" className="mb-12 mt-12 overflow-hidden">
      <SectionTitle title="My Projects" />
      <div className="py-4">
        <SectionHeading title="Latest Projects " highlightText="Showcase" />
      </div>
      <div className="grid grid-cols-2 gap-6 xs:grid-cols-1">
        {projects.length > 0 &&
          projects.map((project, indx) => (
            <ProjectCard
              key={indx}
              name={project.name}
              logo={project.logo}
              url={project.url}
              desc={project.desc}
            />
          ))}
      </div>
    </div>
  );
}

export default Projects;

// Admin@Upspell@2024
