import React, { useRef } from "react";

function Experience({
  position = "Full Stack Developer",
  company = "SHUVO IT",
  start = "January 2022",
  end = "October 2023",
  desc = "Here i did travel agency project that was giving this company a biggest revenue of my great works.",
  isLast = false,
}) {
  return (
    <div className=" exp flex gap-6 text-para dark:text-white xs:gap-4">
      <div className="flex w-32 flex-col">
        <span className="text-lg font-bold text-primary-100">{company}</span>
        <span>{start}-</span>
        <span>{end}</span>
      </div>
      <div className=" after:content-[' '] relative w-[4px] bg-tertiary after:absolute after:-top-0 after:left-[-4px] after:block after:h-3 after:w-3  after:rounded-full after:bg-tertiary dark:bg-darkTertiary dark:after:bg-darkTertiary"></div>
      <div className="flex-1 ">
        <span className="text-lg font-bold text-primary-100">{position}</span>
        <p className={`${isLast ? "mb-0" : "mb-6"} dark:text-white`}>{desc}</p>
      </div>
    </div>
  );
}

export default Experience;
