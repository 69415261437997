import React from "react";
import html from "../images/html.png";
function Skill({ title, parcentage = "85%", logoImg = html }) {
  return (
    <div className="mt-6 flex items-center gap-8">
      <div>
        <img className="w-12" src={logoImg} alt="" />
      </div>
      {/* desc */}
      <div className="flex-1">
        {/* top */}
        <div className="flex ">
          <span style={{ width: parcentage }} className="text-xl">
            {title}
          </span>
          <span>{parcentage}</span>
        </div>

        {/* bottom */}
        <div className="relative">
          <span
            data-content={parcentage}
            className={`relative inline-block h-[6px] w-full rounded-2xl bg-secondary `}
          >
            <span
              style={{ width: parcentage }}
              className={`skill absolute left-0 top-0  inline-block h-[6px] rounded-2xl bg-primary-100`}
            ></span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Skill;
