import React from "react";
import SectionTitle from "./SectionTitle";
import SectionHeading from "./SectionHeading";
import ContactFrom from "./ContactFrom";

function Contacts() {
  return (
    <div id="contactSection" className="mb-12 mt-12">
      <SectionTitle title="I'm just a message away" />
      <div className="py-4">
        <SectionHeading title="Let's Collaborate  " highlightText="With Me" />
      </div>
      <p>
        I am available for freelance work. Connect with me via phone:
        01681846469 or email: ariful@upspell.com
      </p>
      {/* contact form */}
      <div>
        <ContactFrom />
      </div>
    </div>
  );
}

export default Contacts;
